class InfoWindow
  constructor: (provider) ->
    @provider = provider

  getProvider: ->
    @provider

  getTemplate: ->
    document.getElementById('provider-template')

  getProviderPath: ->
    query = Object.assign({},window.filters)
    query['provider-distance'] = @provider.distance
    query = queryString.stringify(query)
    "/providers/#{@provider.id}?#{query}"

  getSidebarNode: (domID = null) ->
    geopins = ["/static/images/daycare_center_pin.png", "/static/images/daycare_home_pin.png"]
    provider = @getProvider()
    instance = @getTemplate().content.cloneNode(true)
    instance.querySelector('.provider-profile-image').innerHTML = "<img src='#{provider.profile_image_url}'>"
    instance.querySelector('.geopin').setAttribute('src', geopins["#{provider.state_license_type}"])
    instance.querySelector('.marker-provider-info').id = domID if domID
    instance.querySelector('.provider-name').textContent = provider.name

    if provider.openings? && provider.openings.length > 0
      instance.querySelector('.provider-openings').textContent = "Openings Available"

    instance.querySelector('.provider-distance').textContent = provider.distance
    instance.querySelector('.provider-link').setAttribute('href', @getProviderPath())
    instance.querySelector('.marker-provider-info-wrapper').setAttribute('data-providerurl', @getProviderPath())
    instance

  getMarkerNode: ->
    provider = @getProvider()
    instance = @getTemplate().content.cloneNode(true)

    instance.querySelector('.provider-profile-image').innerHTML = "<img style='height:100px;' src='#{provider.profile_image_url}'>"
    instance.querySelector('.provider-name').textContent = provider.name
    instance.querySelector('.provider-distance').textContent = provider.distance
    instance.querySelector('.provider-link').setAttribute('href', @getProviderPath())
    instance.querySelector('.provider-link').setAttribute('target', 'blank')
    instance.querySelector('.provider-link').textContent = 'Learn more ›'

    instance.addEventListener 'click', ->
      window.location(@getProviderPath())

    instance

export default InfoWindow
